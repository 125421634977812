import React from 'react'
import Header from "./header"
import Footer from "./footer"

/* Componente para crear la plantilla de la página
  children: Contenido de la página
  location: Localización de la página

  header: Componente para crear el header de la página
  footer: Componente para crear el footer de la página
*/

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <>
        <main className="font-sans bg-white fade-in-fast">
          <div>
            <Header />
            {children}
            <br />
            <br />
            <Footer />
          </div>
        </main>
      </>
    )
  }
}

export default Template
